<template>
  <div class="w-full text-left">
    <p
      class="pb-2 mb-2 border-b border-neutral-300 dark:border-neutral-700 font-medium"
    >
      {{ date.format("LL") }}
    </p>
    <ul class="w-full space-y-2">
      <li
        v-for="event in events"
        class="grid grid-cols-[1fr_3.25rem] items-center gap-2"
      >
        <div>
          <div class="grid grid-cols-[auto_1fr] gap-1 items-center">
            <component
              :is="event.href ? NamiLink : 'span'"
              :to="event.href"
              class="truncate"
            >
              <p class="truncate">
                {{ event.title }}
              </p>
            </component>
            <div class="flex gap-1">
              <span
                v-for="tag in event.tags"
                class="rounded-md px-1 bg-neutral-200 dark:bg-neutral-600 text-xs"
              >
                {{ tag }}
              </span>
            </div>
          </div>
          <p class="text-xs text-neutral-700 dark:text-neutral-300">
            {{ event.description }}
          </p>
        </div>
        <span title="UTC Time" class="text-xs text-right">
          {{ getEventFormattedTime(event) }}
        </span>
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import NamiLink from "~/components/nami/NamiLink.vue";
import type { Dayjs } from "dayjs";

export type Event = {
  title: string;
  description?: string;
  date: string | number | Date | Dayjs;
  tags?: string[];
  href?: string;
};

defineProps<{
  date: Dayjs;
  events: Event[];
}>();

const dayjs = useDayjs();

function getEventFormattedTime(event: Event) {
  if (dayjs.isDayjs(event.date)) {
    return event.date.format("h:mm A");
  } else {
    return dayjs(event.date).format("h:mm A");
  }
}
</script>
